import HomeContainer from '../containers/Home'

const Home = () => {

  return (
    <>
      <HomeContainer />
    </>
  );
}

export default Home;

